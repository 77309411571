import {CreateJaMd, CreateTime} from "../../utility/Utility";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {ModalPicture, ModalPictureRequest} from "./ModalPicture";
import {ModalMap, ModalMapRequest} from "./ModalMap";
import React, {useContext, useState} from "react";
import styled from "styled-components";
import {AlcoholHistoryData} from "../../../reception-service/services/react-common/typings";
import {AppContext} from "../../context/AppContext";

interface Props {
    limit: number;
    page: number;
    total: number;
    alcoholHistories: AlcoholHistoryData[];
    createPageURL: (page: number, limit: number) => string;
    showPagination: boolean;
    isEmployeeSpecified: boolean;
}

export const LogsTable = (props: Props) => {

    const location = useLocation();
    const navigate = useNavigate();
    const {token} = useContext(AppContext);
    const [modalPictureRequest, setModalPictureRequest] = useState<ModalPictureRequest | null>(null);
    const [modalMapRequest, setModalMapRequest] = useState<ModalMapRequest | null>(null);
    const [inputLimit, setInputLimit] = useState<string>(String(props.limit));

    // 表示件数の更新
    const onChangeLimit = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();
        if (/^\d+$/.test(e.currentTarget.value)) {
            setInputLimit(e.currentTarget.value);
        }
    };

    // ページネーションでエンターボタン押下
    const onPaginationSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        if (parseInt(inputLimit) <= 0) {
            alert("件数は1以上でご指定ください");
            return;
        }

        // 件数変更時は1ページ目に戻す
        navigate(props.createPageURL(1, parseInt(inputLimit)));
    };

    // 画像表示
    const onPhoto = (d: AlcoholHistoryData, type: string) => {

        const name = `${d.employee?.sei} ${d.employee?.mei}`;

        let photoPath: string;

        if (type === "attendance") {
            photoPath = d.attendance_detection_img;
        } else {
            photoPath = d.leaving_detection_img;
        }

        const req: ModalPictureRequest = {
            url: `${photoPath}?accessToken=${token}`,
            name: name,
        }

        setModalPictureRequest(req);
    };

    // 画像モーダルを閉じる
    const onPhotoClose = () => {
        setModalPictureRequest(null);
    };

    // 地図表示
    const onMap = (d: AlcoholHistoryData, type: string) => {

        const name = `${d.employee?.sei} ${d.employee?.mei}`;

        let address: string;
        let latitude: number;
        let longitude: number;

        if (type === "attendance") {
            address = d.attendance_address ?? "";
            latitude = d.attendance_latitude ?? 0;
            longitude = d.attendance_longitude ?? 0;
        } else {
            address = d.leaving_address ?? "";
            latitude = d.leaving_latitude ?? 0;
            longitude = d.leaving_longitude ?? 0;
        }

        const req: ModalMapRequest = {
            address: address,
            name: name,
            latitude: latitude,
            longitude: longitude,
        }

        setModalMapRequest(req);
    };

    // 地図モーダルを閉じる
    const onMapClose = () => {
        setModalMapRequest(null);
    };

    // ページオフセットの作成
    const start = (props.page - 1) * props.limit;
    const end = start + props.limit > props.total ? props.total : start + props.limit;
    const pages = Math.ceil(props.total / props.limit);

    return <React.Fragment>
        <StyledTable>
            <thead>
            <tr>
                {props.isEmployeeSpecified && <th>日付</th>}
                {!props.isEmployeeSpecified && <th>名前</th>}
                <th>運転前</th>
                <th>写真</th>
                <th>住所</th>
                <th>運転後</th>
                <th>写真</th>
                <th>住所</th>
            </tr>
            </thead>

            <tbody>
            {props.alcoholHistories.map((d) => {

                return <tr key={`log-${d.id}`}>
                    {props.isEmployeeSpecified && <td>{CreateJaMd(d.ymd)}</td>}
                    {!props.isEmployeeSpecified && <td><NavLink to={`/employee/${d.employee_id}`} state={location}>{d.employee?.sei} {d.employee?.mei}</NavLink></td>}
                    <td>{d.attendance_time ? CreateTime(d.attendance_time) : "-"}</td>
                    <td>{d.attendance_detection_img ? <span onClick={() => onPhoto(d, "attendance")}>登録済み</span> : "未登録"}</td>
                    <td>{d.attendance_address ? <span onClick={() => onMap(d, "attendance")}>{d.attendance_address}</span> : "-"}</td>
                    <td>{d.leaving_time ? CreateTime(d.leaving_time) : "-"}</td>
                    <td>{d.leaving_detection_img ? <span onClick={() => onPhoto(d, "leaving")}>登録済み</span> : "未登録"}</td>
                    <td>{d.leaving_address ? <span onClick={() => onMap(d, "leaving")}>{d.leaving_address}</span> : "-"}</td>
                </tr>

            })}

            </tbody>

        </StyledTable>

        {props.showPagination &&
            <StyledPagination onSubmit={onPaginationSubmit} method="get">
                <span>表示件数</span>
                <input type="number" value={inputLimit} onChange={onChangeLimit} min={1}/>
                <span>件</span>
                <span>{props.total}件中 {start + 1}-{end}件</span>
                {(props.page >= 2) && <NavLink to={props.createPageURL(props.page - 1, props.limit)}>前へ</NavLink>}
                {(props.page < pages) && <NavLink to={props.createPageURL(props.page + 1, props.limit)}>次へ</NavLink>}
            </StyledPagination>
        }

        {/*各モーダル*/}
        {modalPictureRequest && <ModalPicture req={modalPictureRequest} onClose={onPhotoClose}/>}
        {modalMapRequest && <ModalMap req={modalMapRequest} onClose={onMapClose}/>}
    </React.Fragment>

};


// テーブルのスタイル
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .16);

  thead {
    tr {
      th {
        font-weight: 500;
        text-align: center;
        background-color: #fff;
        padding: 10px;
        font-size: 16px;
      }
    }
  }

  tbody {
    tr {
      &:nth-child(2n+1) {
        background-color: #eeeeee;
      }

      &:nth-child(2n) {
        background-color: #fff;
      }


      td {
        font-weight: 400;
        text-align: center;
        padding: 10px;
        font-size: 15px;

        span, a {
          color: #0072B9;
          cursor: pointer;
          text-decoration: none;

          &:hover {
            text-decoration: underline;
          }
        }
      }

    }
  }
`;

// ページネーションのスタイル
const StyledPagination = styled.form`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  align-items: center;

  span {
    margin-right: 15px;
  }

  input {
    margin-right: 5px;
    width: 70px;
    border: 1px solid #ccc;
    padding: 5px 5px;
    text-align: right;
  }

  a {
    color: #0072B9;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

`;