import styled from "styled-components";
import icoClose from "../../images/ico-close.svg";
import {ModalOverlay} from "./ModalPicture";

export interface ModalMapRequest {
    address: string;
    name: string;
    latitude: number;
    longitude: number;
}

interface Props {
    req: ModalMapRequest;
    onClose: () => void;
}

export const ModalMap = (props: Props) => {

    const url = `https://maps.google.co.jp/maps?t=m&z=15&output=embed&q=${encodeURIComponent(props.req.address)}&ll=${props.req.latitude},${props.req.longitude}`;

    return <ModalOverlay>
        <ModalInner>
            <span className="close" onClick={props.onClose}/>
            <iframe title="地図" src={url} width="100%" height="auto">
            </iframe>
            <span className="name">{props.req.name}</span>
        </ModalInner>
    </ModalOverlay>

};

const ModalInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  // 地図
  iframe {
    display: block;
    width: 680px;
    height: 400px;
    border: none;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, .3);
  }

  // 名前
  .name {
    display: block;
    font-size: 15px;
  }

  // 閉じるボタン
  .close {
    display: block;
    width: 46px;
    height: 46px;
    background-color: #707070;
    background-repeat: no-repeat;
    background-image: url(${icoClose});
    background-size: 24px auto;
    background-position: center center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
`;