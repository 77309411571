import * as React from "react";
import {useContext, useState} from "react";
import {ModalOverlay, ModalPicture, ModalPictureRequest} from "./ModalPicture";
import {AlcoholRecordData, CommonApiResponse} from "../../../reception-service/services/react-common/typings";
import {ConfirmationMethods, CreateJaYMd, CreateTime} from "../../utility/Utility";
import styled from "styled-components";
import {AppContext} from "../../context/AppContext";
import {AlcoholHistoryUpdateRequest} from "../../typings";
import {ApiUpdateHistory} from "../../api/Api";
import {Message} from "../common/Message";
import {FormError} from "../common/FormError";

interface Props {
    alcoholRecord: AlcoholRecordData;
    onClose: (reload: boolean) => void;
}

export const ModalDetail = (props: Props) => {

    const {token, setSpinner, companyAccount} = useContext(AppContext);
    const [modalPictureRequest, setModalPictureRequest] = useState<ModalPictureRequest | null>(null);
    const [confirmationMethod, setConfirmationMethod] = useState<string>(props.alcoholRecord.confirmation_method ?? "");
    const [confirmationMethodOther, setConfirmationMethodOther] = useState<string>(props.alcoholRecord.confirmation_method_other ?? "");
    const [usingMachine, setUsingMachine] = useState<string>(props.alcoholRecord.using_machine ? "1" : "0");
    const [isDrinking, setIsDrinking] = useState<string>(props.alcoholRecord.is_drinking ? "1" : "0");
    const [instruction, setInstruction] = useState<string>(props.alcoholRecord.instruction ?? "");
    const [memo, setMemo] = useState<string>(props.alcoholRecord.memo ?? "");
    const [errors, setErrors] = useState<{ [key: string]: string }>({});
    const [message, setMessage] = useState<string | null>(null);

    // 有無
    const booleans: { [key: string]: string } = {
        "1": "有",
        "0": "無",
    };

    // 拡大画像
    const onPhoto = () => {

        const req: ModalPictureRequest = {
            url: `${props.alcoholRecord.detection_img}?accessToken=${token}`,
            name: "アルコール検知結果写真",
        };

        setModalPictureRequest(req);
    };

    const onPhotoClose = () => {
        setModalPictureRequest(null);
    };

    // 変更イベントハンドラー
    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>): void => {

        switch (e.currentTarget.name) {
            case "confirmation_method":
                setConfirmationMethod(e.currentTarget.value);

                if (e.currentTarget.value !== "other") {
                    // その他でない場合、その他テキストを空にする
                    setConfirmationMethodOther("");
                }

                break;
            case "confirmation_method_other":
                setConfirmationMethodOther(e.currentTarget.value);
                break;
            case "instruction":
                setInstruction(e.currentTarget.value);
                break;
            case "memo":
                setMemo(e.currentTarget.value);
                break;
            case "using_machine":
                setUsingMachine(e.currentTarget.value);
                break;
            case "is_drinking":
                setIsDrinking(e.currentTarget.value);
                break;
        }

    };

    // 送信ハンドラー
    const onSubmit = (e: React.FormEvent<HTMLFormElement>): void => {

        e.preventDefault();

        if (!companyAccount?.is_alcohol_admin) {
            // 安全運転管理者でなければ送信しない
            return;
        }

        const req: AlcoholHistoryUpdateRequest = {
            id: props.alcoholRecord.id,
            confirmation_method: confirmationMethod,
            confirmation_method_other: confirmationMethodOther,
            using_machine: usingMachine === "1",
            is_drinking: isDrinking === "1",
            instruction: instruction,
            memo: memo,
        };

        setSpinner(true);

        setErrors({});

        ApiUpdateHistory(req)
            .then((res) => {

                const data = res.data as CommonApiResponse;
                console.log(data);

                props.onClose(true);

            })
            .catch((err) => {
                console.log(err.response);

                if (err.response.status === 406) {
                    // バリデーションエラーの場合
                    console.log(err.response.data);

                    const keys = Object.keys(err.response.data);

                    const _tmp: { [key: string]: string } = {};

                    keys.forEach((k) => {
                        _tmp[k] = err.response.data[k];
                    });

                    setErrors(_tmp);
                } else if (err.response.data && err.response.data.message) {
                    setMessage(err.response.data.message);
                }

            })
            .finally(() => {
                setSpinner(false);
            });
    };

    return <ModalOverlay>

        <ModalInner>

            <div className="detection_img">
                <h4>アルコール検知結果写真</h4>
                <img src={`${props.alcoholRecord.detection_img}?accessToken=${token}`} alt="アルコール検知結果写真" title="クリックして拡大" onClick={onPhoto}/>
            </div>

            <form onSubmit={onSubmit}>

                <Message message={message}/>

                <table>
                    <tbody>
                    <tr>
                        <th>運転者</th>
                        <td>{props.alcoholRecord.employee?.sei} {props.alcoholRecord.employee?.mei}</td>
                    </tr>
                    <tr>
                        <th>申請種別 / 時間</th>
                        <td>
                            {props.alcoholRecord.type === "attendance" && "運転前"}
                            {props.alcoholRecord.type === "leaving" && "運転後"}
                            &nbsp;/ {CreateJaYMd(props.alcoholRecord.time ?? "")}{CreateTime(props.alcoholRecord.time ?? "")}
                        </td>
                    </tr>
                    <tr>
                        <th>車両ナンバー</th>
                        <td>{props.alcoholRecord.car?.number ?? "-"}</td>
                    </tr>
                    <tr>
                        <th>位置情報</th>
                        <td>{props.alcoholRecord.address ?? "-"}</td>
                    </tr>
                    <tr>
                        <th>確認方法</th>
                        <td>

                            <div className="radio-area">
                                {Object.keys(ConfirmationMethods).map((k) => {

                                    return <div key={`method-${k}`}>
                                        <label>
                                            <input type="radio" name="confirmation_method" value={k} onChange={onChange} checked={confirmationMethod === k}/>
                                            {ConfirmationMethods[k]}
                                        </label>
                                    </div>
                                })}

                                <input type="text" name="confirmation_method_other" value={confirmationMethodOther} onChange={onChange} disabled={confirmationMethod !== "other"}/>
                            </div>

                            <FormError message={errors["confirmation_method"]}/>
                            <FormError message={errors["confirmation_method_other"]}/>
                        </td>
                    </tr>
                    <tr>
                        <th>検知機使用の有無</th>
                        <td>
                            <div className="radio-area">
                                {Object.keys(booleans).map((k) => {

                                    return <div key={`using_machine-${k}`}>
                                        <label>
                                            <input type="radio" name="using_machine" value={k} onChange={onChange} checked={usingMachine === k}/>
                                            {booleans[k]}
                                        </label>
                                    </div>
                                })}
                            </div>
                            <FormError message={errors["using_machine"]}/>
                        </td>
                    </tr>
                    <tr>
                        <th>酒気帯びの有無</th>
                        <td>
                            <div className="radio-area">
                                {Object.keys(booleans).map((k) => {

                                    return <div key={`is_drinking-${k}`}>
                                        <label>
                                            <input type="radio" name="is_drinking" value={k} onChange={onChange} checked={isDrinking === k}/>
                                            {booleans[k]}
                                        </label>
                                    </div>
                                })}
                            </div>
                            <FormError message={errors["is_drinking"]}/>
                        </td>
                    </tr>
                    </tbody>
                </table>
                <table>
                    <tbody>
                    <tr>
                        <th>指示事項</th>
                        <td>
                            <textarea name="instruction" value={instruction} onChange={onChange} readOnly={!companyAccount?.is_alcohol_admin}/>
                            <FormError message={errors["instruction"]}/>
                        </td>
                    </tr>
                    <tr>
                        <th>その他（備考）</th>
                        <td>
                            <textarea name="memo" value={memo} onChange={onChange} readOnly={!companyAccount?.is_alcohol_admin}/>
                            <FormError message={errors["memo"]}/>
                        </td>
                    </tr>

                    </tbody>
                </table>

                <div className="buttons">
                    <button type="button" onClick={() => props.onClose(false)}>キャンセル</button>
                    {companyAccount?.is_alcohol_admin && <button type="submit">保存</button>}
                </div>

            </form>

        </ModalInner>

        {modalPictureRequest && <ModalPicture req={modalPictureRequest} onClose={onPhotoClose}/>}

    </ModalOverlay>

};

const ModalInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: #fff;
  max-width: 839px;
  width: 95%;
  padding: 25px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, .3);

  .detection_img {
    position: absolute;
    top: 25px;
    right: 25px;
    width: 200px;
    height: 200px;
    text-align: center;

    h4 {
      font-size: 15px;
      font-weight: 500;
      text-align: center;
      margin-bottom: 10px;
    }

    img {
      max-height: 130px;
      width: auto;
      cursor: pointer;
      max-width: 100%;
      height: auto;
    }
  }

  // テーブル
  table {
    width: 100%;
    border-collapse: collapse;
    margin-bottom: 20px;

    tbody {
      tr {
        th {
          font-size: 15px;
          font-weight: 500;
          text-align: left;
          padding: 15px 20px 15px 10px;
          white-space: nowrap;
          width: 150px !important;
        }

        td {
          width: 100%;
          padding: 15px 0;
        }
      }
    }
  }

  // ラジオボタン
  .radio-area {
    display: flex;
    justify-content: flex-start;
    align-items: center;

    div {
      margin-right: 20px;

      input {
        margin-right: 5px;
      }

      label {
        font-size: 15px;
        white-space: nowrap;
      }
    }

    input[type=text] {
      width: 100%;
    }
  }

  // 入力フォーム
  input[type=text] {
    border: 1px solid #aaa;
    height: 38px;
    padding: 0 10px;
    font-size: 15px;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
    display: block;
    width: 100%;

    &[disabled] {
      background-color: #ccc;
    }
  }

  textarea {
    border: 1px solid #aaa;
    height: 76px;
    padding: 8px;
    font-size: 15px;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
    display: block;
    width: 100%;
    resize: vertical;

    &:read-only {
      background-color: #eee;
    }
  }

  // ボタンエリア
  .buttons {
    text-align: right;

    button {
      background-color: #fff;
      border-radius: 2px;
      box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
      border: 1px solid #28A0A0;
      color: #28A0A0;
      font-size: 16px;
      height: 38px;
      width: 105px;
      cursor: pointer;

      &[type=submit] {
        background-color: #28A0A0;
        color: #fff;
      }

      &:last-child {
        margin-left: 15px;
      }
    }

  }

  // エラー
  .errors {
    list-style-type: none;
    background-color: #ecdcdc;
    padding: 15px;
    border-radius: 3px;
    margin-bottom: 20px;

    li {
      color: #fff;
      font-size: 15px;
    }
  }

`;