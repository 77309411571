import {Layout} from "./Layout";
import {CreateDateFromYmd, CreateYmdFromDate, SetPageTitle} from "../../utility/Utility";
import React, {useContext, useEffect, useRef, useState} from "react";
import {AppContext} from "../../context/AppContext";
import {ListByCompanyRequest, ListByCompanyResponse} from "../../typings";
import {ApiHistory} from "../../api/Api";
import {AlcoholHistoryData, CommonApiResponse} from "../../../reception-service/services/react-common/typings";
import styled from "styled-components";
import {useLocation, useNavigate} from "react-router-dom";
import icoCalendar from "../../images/ico-calendar.png";
import 'react-calendar/dist/Calendar.css';
import {LogsTable} from "./LogsTable";
import {Message} from "../common/Message";
import {StyledCalendar} from "../common/StyledCalendar";

export const Logs = () => {

    const location = useLocation();
    const navigate = useNavigate();
    const {setSpinner} = useContext(AppContext);
    const [limit, setLimit] = useState<number>(30);
    const [page, setPage] = useState<number>(1);
    const [alcoholHistories, setAlcoholHistories] = useState<AlcoholHistoryData[]>([]);
    const [total, setTotal] = useState<number>(0);
    const [dt, setDt] = useState<Date>(new Date());
    const [isCalendar, setIsCalendar] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement>(null);
    const [message, setMessage] = useState<string | null>(null);

    document.title = SetPageTitle("運転者ログ");

    useEffect(() => {

        const params = new URLSearchParams(location.search);

        // 各パラメータの型をチェックして、ステート更新
        let _page = params.get("page");
        if (_page && /^\d+$/.test(_page)) {
            if (parseInt(_page) !== page) {
                setPage(parseInt(_page));
            }
        } else {
            _page = String(page);
        }

        let _limit = params.get("limit");
        if (_limit && /^\d+$/.test(_limit)) {
            if (parseInt(_limit) !== limit) {
                setLimit(parseInt(_limit));
            }
        } else {
            _limit = String(limit);
        }

        let _ymd = params.get("ymd");
        if (_ymd && /^\d{4}-\d{1,2}-\d{1,2}$/.test(_ymd)) {
            if (_ymd !== CreateYmdFromDate(dt)) {
                setDt(CreateDateFromYmd(_ymd));
            }
        } else {
            _ymd = CreateYmdFromDate(dt);
        }

        getHistories(parseInt(_page), parseInt(_limit), _ymd);

    }, [location]);

    // ログ取得
    const getHistories = (_page: number, _limit: number, _ymd: string): void => {

        setSpinner(true);

        const req: ListByCompanyRequest = {
            page: _page,
            limit: _limit,
            ymd: _ymd,
            employee_id: null,
        };

        ApiHistory(req)
            .then((res) => {
                const data = res.data as ListByCompanyResponse;
                setAlcoholHistories(data.alcohol_histories);
                setTotal(data.total);
            })
            .catch((err) => {
                const data = err.response.data as CommonApiResponse;
                if (data.message) {
                    setMessage(data.message);
                }
            })
            .finally(() => {
                setSpinner(false);
            });
    }

    // ページ番号を指定してURLを作成(Dateオブジェクトも指定)
    const createPageURLWithDate = (_page: number, _limit: number, _dt: Date): string => {
        const params = new URLSearchParams(window.location.search);
        params.set("page", String(_page));
        params.set("limit", String(_limit))
        params.set("ymd", CreateYmdFromDate(_dt));

        return `${window.location.pathname}?${params.toString()}`;
    };

    // ページ番号を指定してURLを作成
    const createPageURL = (_page: number, _limit: number): string => {
        const params = new URLSearchParams(window.location.search);
        params.set("page", String(_page));
        params.set("limit", String(_limit))
        params.set("ymd", CreateYmdFromDate(dt));

        return `${window.location.pathname}?${params.toString()}`;
    };

    // 検索フォームサブミット
    const onSearchSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();
        navigate(createPageURLWithDate(page, limit, dt));
    };

    // カレンダー表示切り替え
    const onCalendar = () => {
        setIsCalendar(!isCalendar);
    };

    // カレンダー変更イベント
    const onChangeCalendar = (date: Date, e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setIsCalendar(false);
        navigate(createPageURLWithDate(page, limit, date));
    };

    // カレンダーのスタイル定義を返す
    const getCalendarStyle = (): React.CSSProperties => {

        const style: React.CSSProperties = {
            position: "absolute",
            boxShadow: "0 2px 10px rgba(0, 0, 0, .3)",
            zIndex: 10000,
        };

        if (inputRef.current) {
            const rect = inputRef.current.getBoundingClientRect();
            style.top = `${rect.top + rect.height + 10}px`;
            style.left = `${rect.left}px`;
        }

        return style;
    };

    return <Layout>

        <Message message={message}/>

        <StyledSearchForm onSubmit={onSearchSubmit} method="get">
            <input type="text" name="ymd" onClick={onCalendar} value={CreateYmdFromDate(dt)} ref={inputRef} readOnly={true}/>
            {isCalendar && <StyledCalendar style={getCalendarStyle()} onChange={onChangeCalendar} date={dt} />}
        </StyledSearchForm>

        <LogsTable
            limit={limit}
            page={page}
            total={total}
            alcoholHistories={alcoholHistories}
            createPageURL={createPageURL}
            showPagination={true}
            isEmployeeSpecified={false}
        />

    </Layout>

};


const StyledSearchForm = styled.form`
  display: flex;
  align-items: center;
  margin-bottom: 20px;

  input[name=ymd] {
    height: 38px;
    border-radius: 2px;
    background-color: #fff;
    border: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
    width: 256px;
    padding: 0 13px;
    font-size: 16px;

    background-image: url(${icoCalendar});
    background-repeat: no-repeat;
    background-position: right 10px top 50%;
    background-size: 16px auto;
  }

`;
