import styled from "styled-components";
import {NavLink, useLocation} from "react-router-dom";
import logo from "../../images/logo.svg";
import icoRecord from "../../images/ico-record.svg";
import icoLog from "../../images/ico-log.svg";
import icoLogout from "../../images/ico-logout.svg";

interface Menu {
    title: string;
    link: string;
    icon: string;
}

export const Nav = () => {

    const location = useLocation();

    const menus: Menu[] = [
        {
            title: "法定記録",
            link: "/",
            icon: icoRecord,
        },
        {
            title: "運転者ログ",
            link: "/log",
            icon: icoLog,
        },
        {
            title: "ログアウト",
            link: "/logout",
            icon: icoLogout,
        },
    ];

    return <StyledNav>

        <h1>TRIBAWL</h1>

        <ul>
            {
                menus.map((menu, i) => {
                    // 選択状態かチェック
                    let cls = [];
                    if (location.pathname === menu.link) {
                        cls.push("active");
                    }

                    return <li key={`menu-${i}`} className={cls.join(" ")}><NavLink to={menu.link} style={{backgroundImage: `url(${menu.icon})`}} className={`menu-${i}`}>{menu.title}</NavLink></li>
                })
            }
        </ul>

    </StyledNav>

};


const StyledNav = styled.nav`
  width: 182px;
  min-width: 182px;
  box-shadow: 1px 0 2px rgba(0, 0, 0, .16);
  padding: 10px 0;
  background-color: #fff;

  h1 {
    width: 63px;
    height: 63px;
    background-image: url(${logo});
    overflow: hidden;
    white-space: nowrap;
    text-indent: 100%;
    background-size: 100% auto;
    margin: 0 auto 50px auto;
  }

  ul {
    list-style-type: none;

    li {

      a {
        background-repeat: no-repeat;
        background-position: left 22px top 50%;
        color: #000;
        text-decoration: none;
        font-size: 16px;
        display: block;
        line-height: 50px;
        text-align: center;
        background-size: auto 18px;

        &:hover, &.active {
          background-color: #F5F5F5;
        }

        // ログアウト
        &.menu-2 {
          background-position: left 20px top 50%;
          background-size: auto 25px;
        }
      }
    }

  }

`;