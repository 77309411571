import {useContext} from "react";
import {AppContext} from "../../context/AppContext";
import styled from "styled-components";
import userImg from "../../images/user.svg";

export const Header = () => {

    const {companyAccount} = useContext(AppContext);

    if (!companyAccount) {
        return null;
    }

    return <StyledHeader>
        <h1>どこでもアルキット</h1>
        <div className="welcome">
            {companyAccount.name}
        </div>
    </StyledHeader>

};

const StyledHeader = styled.header`
  height: 50px;
  background-color: #28A0A0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 40px;
  width: 100%;
  
  h1 {
    color: #fff;
    font-size: 20px;
  }
  
  .welcome {
    color: #fff;
    background-image: url(${userImg});
    background-repeat: no-repeat;
    background-position: left center;
    padding-left: 25px;
    font-size: 14px;
    letter-spacing: 2px;
  }
  

`;