import React from 'react';
import {AppProvider} from "../context/AppContext";
import {Spinner} from "./common/Spinner";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import {Forgot} from "./auth/Forgot";
import {Login} from "./auth/Login";
import {Reset} from "./auth/Reset";
import {Logs} from "./main/Logs";
import {LogsEmployee} from "./main/LogsEmployee";
import {Records} from "./main/Records";
import {Logout} from "./auth/Logout";

export const App = () => {

    return (
        <AppProvider>

            <Spinner/>

            <BrowserRouter>

                <Routes>
                    <Route path="/login" element={<Login/>}/>
                    <Route path="/forgot" element={<Forgot/>}/>
                    <Route path="/reset" element={<Reset/>}/>
                    <Route path="/logout" element={<Logout/>}/>

                    <Route path="/" element={<Records/>}/>
                    <Route path="/log" element={<Logs/>}/>
                    <Route path="/employee/:employeeId" element={<LogsEmployee/>}/>

                </Routes>

            </BrowserRouter>


        </AppProvider>
    );
}
