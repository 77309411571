import {Layout} from "./Layout";
import {CreateDateFromYmd, CreateYmdFromDate, SetPageTitle} from "../../utility/Utility";
import {Message} from "../common/Message";
import React, {useContext, useEffect, useRef, useState} from "react";
import styled from "styled-components";
import triangle from "../../images/triangle.svg";
import icoCalendar from "../../images/ico-calendar.png";
import {AppContext} from "../../context/AppContext";
import {useLocation, useNavigate} from "react-router-dom";
import {ListRecordRequest, ListRecordResponse} from "../../typings";
import {ApiRecords} from "../../api/Api";
import {RecordsTable} from "./RecordsTable";
import {AlcoholRecordData, CommonApiResponse} from "../../../reception-service/services/react-common/typings";
import {StyledCalendar} from "../common/StyledCalendar";

export const Records = () => {

    const navigate = useNavigate();
    const location = useLocation();
    const {setSpinner, token} = useContext(AppContext);
    const [limit, setLimit] = useState<number>(30);
    const [page, setPage] = useState<number>(1);
    const [isCalendar, setIsCalendar] = useState<boolean>(false);
    const inputRef = useRef<HTMLInputElement | null>(null);
    const [dt, setDt] = useState<Date>(new Date());
    const [driver, setDriver] = useState<string>("");
    const [number, setNumber] = useState<string>("");
    const [confirmed, setConfirmed] = useState<string>("");
    const [total, setTotal] = useState<number>(0);
    const [alcoholRecords, setAlcoholRecords] = useState<AlcoholRecordData[]>([]);
    const [message, setMessage] = useState<string | null>(null);

    const confirmedOptions: { [key: string]: string } = {
        "not_confirmed": "未確認",
        "confirmed": "確認済",
    };

    document.title = SetPageTitle("法定記録");

    useEffect(() => {

        const params = new URLSearchParams(location.search);

        // 各パラメータの型をチェックして、ステート更新
        let _page = params.get("page") ?? "";
        if (_page && /^\d+$/.test(_page)) {
            if (parseInt(_page) !== page) {
                setPage(parseInt(_page));
            }
        } else {
            _page = String(page);
        }

        let _limit = params.get("limit") ?? "";
        if (_limit && /^\d+$/.test(_limit)) {
            if (parseInt(_limit) !== limit) {
                setLimit(parseInt(_limit));
            }
        } else {
            _limit = String(limit);
        }

        let _driver = params.get("driver") ?? driver;
        if (_driver && _driver !== driver) {
            setDriver(_driver);
        }

        let _number = params.get("number") ?? number;
        if (_number && _number !== number) {
            setNumber(_number);
        }

        let _confirmed = params.get("confirmed") ?? confirmed;
        if (_confirmed && _confirmed !== confirmed) {
            setConfirmed(_confirmed);
        }

        let _ymd = params.get("ymd") ?? CreateYmdFromDate(dt);
        if (_ymd && _ymd !== CreateYmdFromDate(dt)) {
            setDt(CreateDateFromYmd(_ymd));
        }

        getRecords(parseInt(_page), parseInt(_limit), _ymd, _driver, _number, _confirmed);

    }, [location]);

    const getRecords = (_page: number, _limit: number, _ymd: string, _driver: string, _number: string, _confirmed: string) => {
        setSpinner(true);

        const req: ListRecordRequest = {
            page: _page,
            limit: _limit,
            ymd: _ymd,
            driver: _driver,
            number: _number,
            confirmed: _confirmed,
        }

        ApiRecords(req)
            .then((res) => {

                const data = res.data as ListRecordResponse;
                setTotal(data.total);
                setAlcoholRecords(data.alcohol_records);

            })
            .catch((err) => {

                console.log(err);
                const data = err.response.data as CommonApiResponse;
                if (data.message) {
                    setMessage(data.message);
                }

            })
            .finally(() => {
                setSpinner(false);
            });
    }

    // 検索フォームサブミットハンドラー
    const onSearchSubmit = (e: React.FormEvent<HTMLFormElement>) => {

        e.preventDefault();

        // 検索時は1ページ目に戻す
        setPage(1);
        navigate(createPageURL(1, limit));
    };

    const onCalendar = (e: React.MouseEvent<HTMLInputElement>) => {
        e.preventDefault();
        setIsCalendar(!isCalendar);
    };

    // カレンダー変更イベント
    const onChangeCalendar = (date: Date, e: React.ChangeEvent<HTMLInputElement>) => {
        e.preventDefault();
        setIsCalendar(false);
        setDt(date);
    };

    // カレンダーのスタイル定義を返す
    const getCalendarStyle = (): React.CSSProperties => {

        const style: React.CSSProperties = {
            position: "absolute",
            boxShadow: "0 2px 10px rgba(0, 0, 0, .3)",
            zIndex: 10000,
        };

        if (inputRef.current) {
            const rect = inputRef.current.getBoundingClientRect();
            style.top = `${rect.top + rect.height + 10}px`;
            style.left = `${rect.left}px`;
        }

        return style;
    };

    const onChange = (e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>) => {

        e.preventDefault();

        switch (e.currentTarget.name) {
            case "driver":
                setDriver(e.currentTarget.value);
                break;
            case "number":
                setNumber(e.currentTarget.value);
                break;
            case "confirmed":
                setConfirmed(e.currentTarget.value);
                break;
        }
    };

    // CSVダウンロード
    const onClickDl = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        // Axios経由でダウンロードすると、なぜかBOMが消えるので直接遷移する。
        window.location.href = `${process.env.REACT_APP_API_ENDPOINT}/alcohol-record?ymd=${CreateYmdFromDate(dt)}&csv=1&accessToken=${token}`;
    };

    const createPageURL = (_page: number, _limit: number): string => {

        const params = new URLSearchParams(location.search);
        params.set("driver", driver);
        params.set("number", number);
        params.set("ymd", CreateYmdFromDate(dt));
        params.set("confirmed", confirmed);
        params.set("page", String(_page));
        params.set("limit", String(_limit));

        console.log("page", _page, "limit", _limit);

        return `${location.pathname}?${params.toString()}`;
    };

    const onReload = () => {
        // ステートから読み込み直し
        getRecords(page, limit, CreateYmdFromDate(dt), driver, number, confirmed);
    };

    return <Layout>

        <Message message={message}/>

        <StyledSearchForm onSubmit={onSearchSubmit} method="get">
            <input type="text" name="driver" placeholder="運転者" value={driver} onChange={onChange}/>
            <input type="text" name="number" placeholder="車両番号" value={number} onChange={onChange}/>
            <span>日付</span>
            <input type="text" name="ymd" onClick={onCalendar} value={dt ? CreateYmdFromDate(dt) : ""} ref={inputRef} readOnly={true}/>
            <select name="confirmed" onChange={onChange} value={confirmed}>
                <option value="">指定しない</option>
                {Object.keys(confirmedOptions).map((k) => {
                    return <option value={k} key={`confirmed-${k}`}>{confirmedOptions[k]}</option>
                })}
            </select>
            <button>検索</button>

            <button type="button" className="btn-dl" onClick={onClickDl}>当月分データDL</button>

        </StyledSearchForm>

        {isCalendar && <StyledCalendar style={getCalendarStyle()} onChange={onChangeCalendar} date={dt} />}

        <RecordsTable
            page={page}
            limit={limit}
            total={total}
            alcoholRecords={alcoholRecords}
            createPageURL={createPageURL}
            onReload={onReload}
        />

    </Layout>


};

const StyledSearchForm = styled.form`

  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 40px;

  input, select {
    font-size: 16px;
    height: 38px;
    border-radius: 2px;
    border: none;
    background-color: #fff;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
    width: 204px;
    padding: 0 10px;
    margin-right: 30px;

    &[name=ymd] {
      padding-right: 30px;
      background-image: url(${icoCalendar});
      background-repeat: no-repeat;
      background-position: top 50% right 10px;
    }
  }


  select {
    appearance: none;
    background-repeat: no-repeat;
    background-position: top 50% right 10px;
    background-image: url(${triangle});
  }

  span {
    margin-right: 20px;
  }

  button {
    height: 38px;
    color: #fff;
    background-color: #28A0A0;
    border: none;
    border-radius: 2px;
    box-shadow: 0 1px 3px rgba(0, 0, 0, .16);
    width: 105px;

    &:active {
      opacity: .7;
    }
  }
  
  .btn-dl {
    margin-left: auto;
    padding: 0 15px;
    width: 150px;
  }

`
