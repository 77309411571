import styled from "styled-components";
import icoClose from "../../images/ico-close.svg";

export interface ModalPictureRequest {
    url: string;
    name: string;
}

interface Props {
    req: ModalPictureRequest;
    onClose: () => void;
}

export const ModalPicture = (props: Props) => {

    return <ModalOverlay>
        <ModalInner>
            <span className="close" onClick={props.onClose}/>
            <img src={props.req.url} alt="画像"/>
            <span className="name">{props.req.name}</span>
        </ModalInner>
    </ModalOverlay>

};

export const ModalOverlay = styled.div`
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, .2);
  position: fixed;
  top: 0;
  left: 0;
  z-index: 999;
  overflow: scroll;
`;

const ModalInner = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  // 画像
  img {
    margin: 0 auto 10px auto;
    display: block;
    max-width: 700px;
    height: auto;
    max-height: 700px;
    width: auto;
    box-shadow: 1px 2px 10px rgba(0, 0, 0, .3);
  }

  // 名前
  .name {
    display: block;
    font-size: 15px;
  }

  // 閉じるボタン
  .close {
    display: block;
    width: 46px;
    height: 46px;
    background-color: #707070;
    background-repeat: no-repeat;
    background-image: url(${icoClose});
    background-size: 24px auto;
    background-position: center center;
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
  }
`;