import {Layout} from "./Layout";
import React, {useContext, useEffect, useState} from "react";
import {ApiGetEmployee, ApiHistory} from "../../api/Api";
import {AppContext} from "../../context/AppContext";
import {NavLink, useLocation, useNavigate, useParams} from "react-router-dom";
import {AlcoholHistoryData, CommonApiResponse, EmployeeData} from "../../../reception-service/services/react-common/typings";
import {GetEmployeeResponse, ListByCompanyRequest, ListByCompanyResponse} from "../../typings";
import {CreateDateFromYm, CreateYmdFromDate, CreateYmFromDate, GetNextMonth, GetPrevMonth} from "../../utility/Utility";
import {LogsTable} from "./LogsTable";
import styled from "styled-components";
import {Message} from "../common/Message";

export const LogsEmployee = () => {

    const location = useLocation();
    const {employeeId} = useParams();
    const {setSpinner} = useContext(AppContext);
    const navigate = useNavigate();
    const [dt, setDt] = useState<Date>(new Date());
    const [employee, setEmployee] = useState<EmployeeData | null>(null);
    const [total, setTotal] = useState<number>(0);
    const [alcoholHistories, setAlcoholHistories] = useState<AlcoholHistoryData[]>([]);
    const [old, setOld] = useState<string>("");
    const [isInit, setIsInit] = useState<boolean>(false);
    const [message, setMessage] = useState<string | null>(null);

    // 月ごとに全件一気に取得するので、stateではなく、定数化
    const page = 1;
    const limit = 31;

    useEffect(() => {

        const params = new URLSearchParams(location.search);

        let isPageChanged = false;

        let _dt = dt;
        let _ym = params.get("ym");
        if (_ym && /^\d{4}-\d{1,2}$/.test(_ym)) {
            if (_ym !== CreateYmdFromDate(dt)) {
                _dt = CreateDateFromYm(_ym);
                setDt(_dt);
                isPageChanged = true;
            }
        }

        if (isPageChanged && isInit) {
            // パラメータ変更時、履歴を取得
            getHistories(_dt);
        }

        if (!isInit) {
            // 起動時のみの処理
            getEmployee();
            getHistories(_dt);

            // 一つ前のURLを保存
            if(location.state) {
                const oldLocation = location.state as Location;
                setOld(`${oldLocation.pathname}${oldLocation.search}`);
            } else {
                setOld("/log");
            }

            setIsInit(true);
        }

    }, [location]);

    // 従業員情報を取得
    const getEmployee = () => {

        setSpinner(true);

        ApiGetEmployee(employeeId ?? "")
            .then((res) => {

                const data = res.data as GetEmployeeResponse;
                setEmployee(data.employee);
            })
            .catch((err) => {
                const data = err.response.data as CommonApiResponse;
                if (data.message) {
                    setMessage(data.message);
                }
                navigate(`/log`);
            })
            .finally(() => {
                setSpinner(false);
            });
    };

    // ログ取得
    const getHistories = (_dt: Date): void => {

        setSpinner(true);

        const req: ListByCompanyRequest = {
            page: page,
            limit: limit,
            employee_id: employeeId ?? "",
            ym: CreateYmFromDate(_dt),
        };

        ApiHistory(req)
            .then((res) => {
                const data = res.data as ListByCompanyResponse;
                setAlcoholHistories(data.alcohol_histories);
                setTotal(data.total);
            })
            .catch((err) => {
                const data = err.response.data as CommonApiResponse;
                if (data.message) {
                    setMessage(data.message);
                }
            })
            .finally(() => {
                setSpinner(false);
            });
    }

    const createPageURL = (): string => {
        return "";
    };

    const getYm = (): string => {
        return CreateYmFromDate(dt).replace("-", "/");
    };

    if (!employee) {
        return null;
    }

    return <Layout>

        <StyledLogsEmployee>

            <h2>{employee.division?.name} {employee.sei} {employee.mei} さんの出退勤情報</h2>

            <Message message={message} />

            <h3>
                <NavLink to={`/employee/${employee.id}?ym=${CreateYmFromDate(GetPrevMonth(dt))}`}>&lt; 前月</NavLink>
                <span>{getYm()}</span>
                <NavLink to={`/employee/${employee.id}?ym=${CreateYmFromDate(GetNextMonth(dt))}`}>翌月 &gt;</NavLink>
            </h3>

            <LogsTable
                limit={limit}
                page={page}
                total={total}
                alcoholHistories={alcoholHistories}
                createPageURL={createPageURL}
                showPagination={false}
                isEmployeeSpecified={true}
            />

            <div className="back">
                <NavLink to={old}>戻る</NavLink>
            </div>

        </StyledLogsEmployee>

    </Layout>;

};

const StyledLogsEmployee = styled.div`

  h2 {
    font-weight: 400;
    font-size: 17px;
    margin-bottom: 30px;
  }

  h3 {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px;
    font-weight: 500;
    margin-bottom: 20px;

    span {
      margin: 0 30px;
    }

    a {
      text-decoration: none;
      color: #0072B9;
      font-weight: 400;
      font-size: 16px;

      &:hover {
        text-decoration: underline;
      }
    }

  }

  .back {
    text-align: center;
    margin-top: 60px;

    a {
      text-decoration: none;
      color: #0072B9;
      font-weight: 400;
      font-size: 16px;

      &:hover {
        text-decoration: underline;
      }
    }
  }

`;