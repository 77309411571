import {CreateTime} from "../../utility/Utility";
import {NavLink, useNavigate} from "react-router-dom";
import React, {useState} from "react";
import styled from "styled-components";
import {AlcoholRecordData} from "../../../reception-service/services/react-common/typings";
import {ModalDetail} from "./ModalDetail";
import {ConfirmationMethods} from "../../utility/Utility";

interface Props {
    limit: number;
    page: number;
    total: number;
    alcoholRecords: AlcoholRecordData[];
    createPageURL: (page: number, limit: number) => string;
    onReload: () => void;
}

export const RecordsTable = (props: Props) => {

    const navigate = useNavigate();
    const [inputLimit, setInputLimit] = useState<string>(String(props.limit));
    const [alcoholRecord, setAlcoholRecord] = useState<AlcoholRecordData | null>(null);

    // 表示件数の更新
    const onChangeLimit = (e: React.ChangeEvent<HTMLInputElement>): void => {
        e.preventDefault();
        if (/^\d+$/.test(e.currentTarget.value)) {
            setInputLimit(e.currentTarget.value);
        }
    };

    // ページネーションでエンターボタン押下
    const onPaginationSubmit = (e: React.FormEvent<HTMLFormElement>): void => {
        e.preventDefault();

        if (parseInt(inputLimit) <= 0) {
            alert("件数は1以上でご指定ください");
            return;
        }

        // 件数変更時は1ページ目に戻す
        navigate(props.createPageURL(1, parseInt(inputLimit)));
    };

    // 行クリック
    const onClickRow = (d: AlcoholRecordData): void => {
        // オブジェクトをコピー(参照元を変更させない)
        const tmp = JSON.parse(JSON.stringify(d)) as AlcoholRecordData;
        setAlcoholRecord(tmp);
    };

    // モーダルを閉じる
    const onCloseModal = (reload: boolean): void => {
        setAlcoholRecord(null);

        if (reload) {
            props.onReload();
        }
    };

    // ページオフセットの作成
    const start = (props.page - 1) * props.limit;
    const end = start + props.limit > props.total ? props.total : start + props.limit;
    const pages = Math.ceil(props.total / props.limit);

    return <React.Fragment>
        <StyledTable>
            <thead>
            <tr>
                <th></th>
                <th>確認者</th>
                <th>運転者</th>
                <th>車両番号</th>
                <th>確認時間</th>
                <th>申請時間</th>
                <th>確認方法</th>
                <th>検知器使用<br/>の有無</th>
                <th>酒気帯び<br/>の有無</th>
                <th>指示事項</th>
                <th>その他<br/>(備考)</th>
                <th></th>
            </tr>
            </thead>

            <tbody>
            {props.alcoholRecords.map((d) => {

                let cls = [];

                if (d.is_confirmed) {
                    cls.push("confirmed");
                } else {
                    cls.push("not_confirmed");
                }

                return <tr key={`log-${d.id}`} className={cls.join(" ")} onClick={() => onClickRow(d)}>
                    <td>
                        {d.type === "attendance" && <React.Fragment>運転前</React.Fragment>}
                        {d.type === "leaving" && <React.Fragment>運転後</React.Fragment>}

                    </td>
                    <td>{d.company_account ? d.company_account.name : "-"}</td>
                    <td>{d.employee ? `${d.employee.sei} ${d.employee.mei}` : "-"}</td>

                    <td>{d.car ? d.car.number : "-"}</td>

                    <td>{d.confirmed_at ? CreateTime(d.confirmed_at) : "-"}</td>
                    <td>{CreateTime(d.time ?? "")}</td>

                    {d.is_confirmed && <React.Fragment>
                        <td>{ConfirmationMethods[d.confirmation_method ?? ""]}</td>
                        <td>{d.using_machine ? "有" : "無"}</td>
                        <td>{d.is_drinking ? "有" : "無"}</td>
                        <td>{d.instruction ?? "-"}</td>
                        <td>{d.memo ?? "-"}</td>
                    </React.Fragment>}

                    {!d.is_confirmed && <React.Fragment>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                        <td>-</td>
                    </React.Fragment>}

                    <td>
                        {d.is_confirmed && "確認済"}
                        {!d.is_confirmed && "未確認"}
                    </td>
                </tr>

            })}

            </tbody>

        </StyledTable>

        <StyledPagination onSubmit={onPaginationSubmit} method="get">
            <span>表示件数</span>
            <input type="number" value={inputLimit} onChange={onChangeLimit} min={1}/>
            <span>件</span>
            <span>{props.total}件中 {start + 1}-{end}件</span>
            {(props.page >= 2) && <NavLink to={props.createPageURL(props.page - 1, props.limit)}>前へ</NavLink>}
            {(props.page < pages) && <NavLink to={props.createPageURL(props.page + 1, props.limit)}>次へ</NavLink>}
        </StyledPagination>

        {alcoholRecord && <ModalDetail
            alcoholRecord={alcoholRecord}
            onClose={onCloseModal}
        />}

    </React.Fragment>

};


// テーブルのスタイル
const StyledTable = styled.table`
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, .16);

  thead {
    tr {
      th {
        font-weight: 500;
        text-align: center;
        background-color: #fff;
        padding: 10px;
        font-size: 16px;
      }
    }
  }

  tbody {
    tr {

      &:hover {
        cursor: pointer;
        opacity: .6;
      }

      &:nth-child(2n+1) {
        background-color: #eeeeee;
      }

      &:nth-child(2n) {
        background-color: #fff;
      }

      &.not_confirmed {
        background-color: #FFEAEA;

        td:last-child {
          color: #E22D2D;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }

      &.confirmed {
        td:last-child {
          color: #0072B9;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }
      }

      td {
        font-weight: 400;
        text-align: center;
        padding: 10px;
        font-size: 14px;

        &:last-child {
          font-size: 15px;
        }

      }

    }
  }
`;

// ページネーションのスタイル
const StyledPagination = styled.form`
  display: flex;
  justify-content: flex-end;
  font-size: 14px;
  align-items: center;

  span {
    margin-right: 15px;
  }

  input {
    margin-right: 5px;
    width: 70px;
    border: 1px solid #ccc;
    padding: 5px 5px;
    text-align: right;
  }

  a {
    color: #0072B9;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

`;