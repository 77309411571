// ページタイトルを設定
export const SetPageTitle = (str: string): string => {
    return `${str} | どこでもアルキット`;
};

// YYYY-MM-DD HH:MM:SSをHH:MMに変換
export const CreateTime = (dt: string): string => {
    const parts = dt.split(" ");
    const timeParts = parts[1].split(":");

    return `${timeParts[0]}:${timeParts[1]}`;
};

// YYYY-MM-DD HH:MM:SSをYYYY-MM-DDに変換
export const CreateYmd = (dt: string): string => {
    const parts = dt.split(" ");
    return parts[0];
};

// Date型をYYYY-MM-DDに変換
export const CreateYmdFromDate = (dt: Date): string => {
    return `${dt.getFullYear()}-${Zerofill(dt.getMonth() + 1, 2)}-${Zerofill(dt.getDate(), 2)}`;
};

// Date型をYYYY-MMに変換
export const CreateYmFromDate = (dt: Date): string => {
    return `${dt.getFullYear()}-${Zerofill(dt.getMonth() + 1, 2)}`;
};

// Date型を渡して翌月のDate型を返す
export const GetNextMonth = (dt: Date): Date => {
    return new Date(dt.getFullYear(), dt.getMonth() + 1, 1);
};

// Date型を渡して前月のDate型を返す
export const GetPrevMonth = (dt: Date): Date => {
    return new Date(dt.getFullYear(), dt.getMonth() - 1, 1);
};

// YYYY-MM-DDをDateに変換
export const CreateDateFromYmd = (ymd: string): Date => {
    try {
        const parts = ymd.split("-");
        return new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, parseInt(parts[2]));
    } catch (e) {
        // エラー時はとりあえず、現在自国のDateを作成
        console.log(e);
        return new Date();
    }
};

// YYYY-MMをDateに変換
export const CreateDateFromYm = (ym: string): Date => {
    try {
        const parts = ym.split("-");
        return new Date(parseInt(parts[0]), parseInt(parts[1]) - 1, 1);
    } catch (e) {
        // エラー時はとりあえず、現在自国のDateを作成
        console.log(e);
        return new Date();
    }
};

// YYYY-MM-DDを2022/04/01（金）に変換
export const CreateJaYMd = (ymd: string): string => {
    const dt = CreateDateFromYmd(ymd);
    return `${dt.getFullYear()}/${Zerofill(dt.getMonth() + 1, 2)}/${Zerofill(dt.getDate(), 2)}（${GetJaDayOfWeek(dt)}）`;
};

// YYYY-MM-DDを04/01（金）に変換
export const CreateJaMd = (ymd: string): string => {
    const dt = CreateDateFromYmd(ymd);
    return `${Zerofill(dt.getMonth() + 1, 2)}/${Zerofill(dt.getDate(), 2)}（${GetJaDayOfWeek(dt)}）`;
};

// YYYY-MM-DDを04/01（金）に変換
export const GetJaDayOfWeek = (dt: Date): string => {
    const weekdays = ["日", "月", "火", "水", "木", "金", "土"];
    return weekdays[dt.getDay()];
};

// 数値のゼロパディング(とりあえず、必要な桁数のみ対応)
export const Zerofill = (i: number, digits: number): string => {
    switch (digits) {
        case 2:
            if (i < 10) {
                return `0${i}`;
            }
            break;
        case 3:
            if (i < 10) {
                return `00${i}`;
            }
            if (i < 100) {
                return `0${i}`;
            }
            break;
    }
    return `${i}`;
}

export const ConfirmationMethods: { [key: string]: string } = {
    "video": "ビデオ通話",
    "phone": "電話",
    "meet": "対面",
    "other": "その他",
};