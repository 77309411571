import axios, {AxiosPromise} from "axios";
import {AlcoholHistoryUpdateRequest, ForgotRequest, ListByCompanyRequest, ListRecordRequest, LoginRequest, ResetRequest, SsoRequest} from "../typings";

// 名前の重複をさけるため、各定数の先頭に "Api" をつける

// 共通のリクエストインスタンス
const AxiosInterface = axios.create();
AxiosInterface.interceptors.request.use((config) => {
    const token: string = localStorage.getItem("alcohol_token") ? localStorage.getItem("alcohol_token") as string : "";
    config.headers = {
        "Authorization": `Bearer ${token}`,
    };
    return config;
});

// ドメイン名を連結して返す
const createEndpointUrl = (path: string): string => {
    return `${process.env.REACT_APP_API_ENDPOINT}${path}`;
}

// ログイン
export const ApiSSO = (req: SsoRequest): AxiosPromise<any> => {
    return AxiosInterface.post(createEndpointUrl(`/company-account/sso`), req);
};

// ログイン
export const ApiLogin = (req: LoginRequest): AxiosPromise<any> => {
    return AxiosInterface.post(createEndpointUrl(`/company-account/login`), req);
};

// ログアウト
export const ApiLogout = (): AxiosPromise<any> => {
    return AxiosInterface.get(createEndpointUrl(`/company-account/logout`));
};

// パスワード再発行メール送信
export const ApiForgot = (req: ForgotRequest): AxiosPromise<any> => {
    return AxiosInterface.post(createEndpointUrl(`/company-account/forgot`), req);
};

// パスワード再設定
export const ApiReset = (req: ResetRequest): AxiosPromise<any> => {
    return AxiosInterface.post(createEndpointUrl(`/company-account/reset`), req);
};

// 自分の情報取得
export const ApiMe = (): AxiosPromise<any> => {
    return AxiosInterface.get(createEndpointUrl(`/company-account/me`));
};

// 申請 / 記録の取得
export const ApiRecords = (req: ListRecordRequest): AxiosPromise<any> => {
    const params = new URLSearchParams();
    params.append("page", String(req.page));
    params.append("limit", String(req.limit));
    params.append("ymd", String(req.ymd));
    params.append("driver", String(req.driver ?? ""));
    params.append("number", String(req.number ?? ""));
    params.append("confirmed", String(req.confirmed ?? ""));
    params.append("csv", String(req.csv ?? ""));

    return AxiosInterface.get(createEndpointUrl(`/alcohol-record?${params.toString()}`));
};

// 履歴の取得
export const ApiHistory = (req: ListByCompanyRequest): AxiosPromise<any> => {
    const params = new URLSearchParams();
    params.append("page", String(req.page));
    params.append("limit", String(req.limit));
    if (req.employee_id) {
        params.append("employee_id", req.employee_id);
    }
    if (req.ymd) {
        params.append("ymd", req.ymd);
    }
    if (req.ym) {
        params.append("ym", req.ym);
    }
    return AxiosInterface.get(createEndpointUrl(`/alcohol-history?${params.toString()}`));
};

// 従業員情報取得
export const ApiGetEmployee = (employeeId: string): AxiosPromise<any> => {
    return AxiosInterface.get(createEndpointUrl(`/employee/get/${employeeId}`));
};


// 確認処理
export const ApiUpdateHistory = (req: AlcoholHistoryUpdateRequest): AxiosPromise<any> => {
    return AxiosInterface.post(createEndpointUrl(`/alcohol-history/update`), req);
};


